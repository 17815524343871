import { number } from "@amcharts/amcharts4/core";

export const DOCS_LEVEL = {
  GLOBAL_REFERENCE: "Global Reference",
  CANVAS_TRAINING: "CANVAS Training",
  TCP_TEMPLATES: "TCP & AOP Templates",
  BRAND_DOCUMENTS: "Brand Documents",
  INPUT_FILES: "Input Files",
  SOCIAL_ENGAGEMENT: "Social Engagement & Listening",
  CAMPAIGN_STUDIES: "Campaign Studies",
};

export const PAGE_MODES = {
  HOME: "HOME",
  CREATE: "CREATE",
  EDIT: "EDIT",
  DELETE: "DELETE",
  BLKUPLOAD: "BLKUPLOAD",
};

export const BREADCRUMBS_REFERENCE_TYPES = {
  MARKET: "MARKET",
  PORTFOLIO: "PORTFOLIO",
  PORTFOLIO_GROUP: "PORTFOLIO_GROUP",
  PLAN: "PLAN",
  MJTBD: "MJTBD",
  PLAN_ENTITY: "PLAN_ENTITY",
  UPFRONT_SCENARIO: "UPFRONT_SCENARIO",
  COMMSTASK: "COMMSTASK",
  CAMPAIGN: "CAMPAIGN",
};

export const BREADCRUMBS_LAST_NODE_TYPES = {
  ...BREADCRUMBS_REFERENCE_TYPES,
  PORTFOLIO_ALLOCATION: "PORTFOLIO_ALLOCATION",
  MEDIA_CHANNEL_CALENDAR_PLAN: "MEDIA_CHANNEL_CALENDAR_PLAN",
  MEDIA_CHANNEL_CALENDAR_PF: "MEDIA_CHANNEL_CALENDAR_PF",
  TCP_DOCUMENT_TRACKER: "TCP_DOCUMENT_TRACKER",
  UPFRONT_SCENARIO: "UPFRONT_SCENARIO",
  UPFRONT_PLAN: "UPFRONT_PLAN",
  ANNUAL_BRAND_TARGETS: "ANNUAL_BRAND_TARGETS",
  FOUNDATIONAL_BRAND_DOCUMENTS: "FOUNDATIONAL_BRAND_DOCUMENTS",
  TCP_ARCHITECTURE: "TCP_ARCHITECTURE",
  OPP_BRIEF: "OPP_BRIEF",
  CAMPAIGN_IDEA: "CAMPAIGN_IDEA",
  SCENARIO: "SCENARIO",
  TCP_TRACKER: "TCP Status",
};

export const GLOBAL_ELEMENTS = {
  DEFAULT: {
    id: 0,
    displayName: "",
  },
  TOOLS: {
    id: 2,
    displayName: "Documents",
  },
  DOCUMENTS: {
    id: 2,
    displayName: "Document Center",
    level: DOCS_LEVEL.GLOBAL_REFERENCE,
    subPath: "",
  },
  ALERTS: {
    id: 3,
    displayName: "Notifications",
  },
  HELP: {
    id: 4,
    displayName: "HELP @ MSP",
  },
  USER_PROFILE: {
    id: 5,
    displayName: "User Profile",
  },
  USER_MGMT: {
    id: 6,
    displayName: "User Management",
  },
  BRAND_MGMT: {
    id: 7,
    displayName: "Brand Management",
  },
  OPTIMIZER_Q: {
    id: 8,
    displayName: "Optimizer Queue",
  },
  NEW_SCENARIO: {
    id: 9,
    displayName: "Upfront Scenario Planning for ##brandName##",
  },
  NEW_CT_SCENARIO: {
    id: 10,
    displayName: "Comms Task Scenario Planning for ##mjtbtName##",
  },
  SCENARIO_PLOT: {
    id: 11,
    displayName: "Comms Task Scenario Planning for ##brandName##",
    chartData: {},
  },
  PERFORMANCE_TARGET: {
    id: 12,
    displayName: "Annual Brand Marketing Targets",
    targetData: {},
    planId: 0,
    year: 0,
  },
  NEW_MJTBD: {
    id: 13,
    displayName: "Marketing Job to be Done",
    mjtbdData: {},
  },
  TCP_ARCHITECTURE: {
    id: 14,
    displayName: "Brand Strategy",
    tcpData: {},
  },
  OPP_BRIEF: {
    id: 15,
    displayName: "Opportunity Brief",
    oppBriefData: {},
  },
  CAMPAIGN_IDEA: {
    id: 16,
    displayName: "Campaign Idea",
    campIdeaData: {},
  },
  DATORAMA_DASHBOARD: {
    id: 17,
    displayName: "Real Time Optimization Dashboard",
    datoramaData: {},
  },
  CPERF_DASHBOARD: {
    id: 18,
    displayName: "Campaign Performance Dashboard",
    cPerfData: {},
  },
  TCP_DOCUMENT_CENTER: {
    id: 19,
    displayName: "TCP Documents Center",
    tcpDocData: {},
  },
  KEY_MEDIA_OUTCOME_PLANS: {
    id: 20,
    displayName: "Enter Planned Target Values For Widget",
    planWidgetData: {},
  },
  TCP_PORTFOLIO_TRACKER: {
    id: 21,
    displayName: `Track My Portfolio's TCP Progress`,
    portfolioTCPTracker: {},
  },
  WIDGET_CONFIGURATION: {
    id: 22,
    displayName: `Widget Configuration`,
  },
  PORTFOLIO_RTM_DASH: {
    id: 23,
    displayName: `Manage Portfolio RTO Dashboard`,
  },
    BRAND_RTM_DASH: {
        id: 27,
        displayName: `Manage Brand RTO Dashboard`,
    },
  PORTFOLIO_RTO_DASH: {
    id: 24,
    displayName: `Portfolio RTO Dashboard`,
    portfoliodata: {},
  },
  CHANNELS_TOUCHPOINTS: {
    id: 25,
    displayName: `Manage TouchPoints Channels`,
  },
  TOOLTIP_MANAGEMENT: {
    id: 26,
    displayName: `Tooltip Management`,
  },
    BRANDMAPPING_MANAGEMENT: {
        id: 28,
        displayName: `Brand Mapping Management`,
    },

  MEDIA_TRANSPARENCY_DASH: {
    id: 29,
    displayName: `Media Transparency Dashboard`,
    type: "MTD",
    portfoliodata: {},
  },
  CREATIVE_MEDIA_PERFORMANCE_DASH: {
    id: 30,
    displayName: `Creative Performance Dashboard`,
    type: "CPD",
    portfoliodata: {},
  },
  NEW_ROISCENARIO: {
    id: 31,
    displayName: "ROI API Scenario Planning for ##brandName##",
  },
};
export const BRAND_LEVEL = {
  PROGRAM: "PROGRAM",
  PLAN: "PLAN",
};

export const CALENDER_ROUTES = {
  PORTFOLIO: "portfolio",
  PLAN: "plan",
};

export const CALENDER_TABLE_MODES = {
  PLAN: "plan",
  SUB_BRAND: "sub_brand",
  CHANNEL: "channel",
  MJTBD: "mjtbd",
  COMMSTASKS: "commstask",
  SUB_BRAND_USER: "subBrands-user",
  CHANNEL_USER: "channel-user",
};

export const MEDIA_CHANNEL_REFERENCES = {
  PORTFOLIO: "Portfolio",
  MARKET: "Market",
  MASTERBRAND: "Masterbrand",
  PORTFOLIO_GROUP: "Portfolio Group",
  SUB_BRAND: "Sub Brand",
};

export const ANALYTICS_TYPE = {
  COMMSTASK: "COMMSTASK",
  UPFRONT: "UPFRONT",
};

export const SHAREPOINT_DOC_PATH = {
  TCP: "TCP Templates",
  FOUNDATIONAL: "Foundational Brand Documents",
  ATTACHMENTS: "Attachments",
  OPPORTUNITY_BRIEF: "TCP Opportunity Brief",
  CAMPAIGN_IDEA: "TCP Campaign Ideas",
  AUDIENCE: "TCP Audience Considerations",
  TCP_ARCHITECTURE: "TCP Architecture",
  TCP_ACT_CALENDER: "TCP Activation Calendar",
  INPUT_FILES: "Input Files",
  CDNA: "cDNA",
  LEARNING_PLAN: "Campaign Learning Plan",
  CAMPAIGN_STUDIES: "Campaign Studies",
};

export const FILE_NAMES = {
  OPP_BRIEF_AUDIENCE: "TCP Audience Considerations",
  OPP_BRIEF_LEARNING: "Campaign Learning Plan",
  GROWTH_STRATEGY: "3-year_growth_strategy",
  BRAND_IDEA: "big_brand_idea",
  PURPOSEFUL_POSITIONING: "purposeful_positioning",
  ACTIVATION_CALENDER: "TCP Activation Calendar",
};

export const FILE_TYPE = {
  POSITIONING: "positioning",
  BRAND_IDEA: "brand_idea",
  GROWTH_STRATEGY: "growth_strategy",
  FOUNDATIONAL_ATTACHMENT: "foundAttach",
  ACTIVATION_CALENDER: "tcpActcal",
  AUDIENCE: "audience",
  LEARNING_PLAN: "learningPlan",
  IDEA_FORM: "ideaForm",
};

export const CALENDAR_MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const STATUS_LIST = ["DRAFT", "LATEST WORKING", "LATEST EXECUTION PLAN"];
export const MINIFIED_STATUS_LIST = ["DRAFT", "LATEST WORKING"];
export const FINAL_STATUS_LIST = ["LATEST EXECUTION PLAN", "RE-PLAN"];
export const ONLY_REPLAN_STATUS_LIST = ["RE-PLAN"];
export const STATUS_LIST_TYPES = {
  COMPLETE: "LATEST WORKING",
  DRAFT: "DRAFT",
  FINAL: "LATEST EXECUTION PLAN",
  REPLAN: "RE-PLAN",
  OPTIMIZING: "OPTIMIZING",
  OPTIMIZED: "OPTIMIZED",
};

export const OPTIMIZE_STATUS = {
  OPTIMIZING: "OPTIMIZING",
  OPTIMIZED: "OPTIMIZED",
};

export const FORM_REFERENCE_TYPES = {
  MJTBD_FORM: "MJTBD_FORM",
  TCP_ARCH: "TCP_ARCH",
  OP_BRIEF: "OP_BRIEF",
  CAMP_IDEA: "CAMP_IDEA",
  PLAN: "PLAN",
  PORTFOLIO: "PORTFOLIO",
  UPFRONT_PLANNER: "UPFRONT_PLANNER",
  COMMS_TASK: "COMMS_TASK",
  PORTFOLIO_ALLOCATION: "PORTFOLIO_ALLOCATION",
};

export const ALERT_STATUS = {
  NEW: "NEW",
  VIEWED: "VIEWED",
};

export const ANNUAL_BRAND_IMAGE_PATH = {
  "2022_United States_cheetos": {
    BRAND_IDEA: "assets/images/help-thumbnails/2022_cheetos_big_brand_idea.png",
    POSITIONING:
      "assets/images/help-thumbnails/2022_cheetos_purposeful_positioning.png",
    GROWTH_STRGTY: "assets/images/help-thumbnails/section-thumb-3.png",
  },
  "2022_Russian Federation_lays": {
    BRAND_IDEA: "assets/images/help-thumbnails/2022_lays_big_brand_idea.png",
    POSITIONING:
      "assets/images/help-thumbnails/2022_lays_purposeful_positioning.png",
    GROWTH_STRGTY:
      "assets/images/help-thumbnails/2022_lays_3-year_growth_strategy.png",
  },
  "2022_United States_mountain dew": {
    BRAND_IDEA:
      "assets/images/help-thumbnails/2022_mountaindew_big_brand_idea.png",
    POSITIONING:
      "assets/images/help-thumbnails/2022_mountain dew_purposeful_positioning.png",
    GROWTH_STRGTY: "assets/images/help-thumbnails/section-thumb-3.png",
  },
  DEFAULT: {
    BRAND_IDEA: "assets/images/help-thumbnails/section-thumb-1.png",
    POSITIONING: "assets/images/help-thumbnails/section-thumb-2.png",
    GROWTH_STRGTY: "assets/images/help-thumbnails/section-thumb-3.png",
  },
};

export const TCP_CHART_LEGENDS = {
  KPI_FRAMEWORK: "KPI Framework",
  ARCHITECTURE: "Architecture",
  OPP_BRIEF: "Opportunity",
  IDEA: "Idea",
  CONNECTIONS: "Connections",
};

export const TCP_DOC_ID = {
  KPI_FRAMEWORK: "KPI Framework",
  ARCHITECTURE: "Architecture",
  ACTIVATION_CALENDER: "Activation Calendar",
  OPP_BRIEF: "Opportunity Brief",
  AUDIENCE_CONSIDERATION: "Audiences & Other Considerations",
  CAMPAIGN_IDEA: "Campaign Idea",
  CONTENT_DISTRIBUTION_STRATEGY: "Content Distribution Strategy",
  CONNECTION_MAP: "Connections Map",
  BRAND_GROWTH: "Brand Growth",
};

export const TCP_PROGRESS_INPUT_KEYS = {
  KPI_FRAMEWORK: {
    MJTBD_INPUTS: ["brandObjective", "commsObjective"],
    OPP_INPUTS: ["successKPIs"],
    IDEA_INPUTS: ["campaignGoal"],
    CHANNEL_INPUTS: ["optimizationKPIs"],
  },
  ARCHITECTURE: {
    TCP_INPUTS: ["bigBrandIdea", "brandProblemToSolve"],
    MJTBD_INPUTS: [
      "commsObjective",
      "associatedFormat",
      "mjtbdFormTitle",
      "audiencePriority",
    ],
    IDEA_INPUTS: ["campaignGoal"],
  },
  OPPORTUNITY: {
    OPP_INPUTS: [
      "assignmentName",
      "mjtbdId",
      "approversList",
      "commsObjective",
      "problem",
      "advantage",
      "insight",
      "hashTag",
      "strategy",
      "deliverables",
      "considerations",
      "successKPIs",
      "workingMediaBudget",
    ],
  },
  CAMPAIGN_IDEA: {
    IDEA_INPUTS: [
      "situation",
      "target",
      "insight",
      "whatWouldWeDo",
      "ciTitle",
      "campaignImage",
      "results",
      "connectionToBBI",
      "addionalInsight",
    ],
  },
  CONNECTIONS: {
    CHANNEL_INPUTS: [
      "commsTaskName",
      "audience",
      "keyInterception",
      "touchPointName",
      "optimizationKPIs",
      "touchPointContent",
    ],
  },
};

export const AGGR_VIEW = {
  TCP: "TCP",
  PLANIT: "PLANIT",
};

export const DASHBOARD_MESSAGE = {

    CPERF_SUCCESS: '##PLANNAME## does not have a Campaign Performance dashboard. Please engage your MSP product team for further assistance.',
    DATORAMA_SUCCESS: '##PLANNAME## does not have an RTO dashboard. Please engage your MSP product team for further assistance.',
    ERROR: 'Something went wrong, Please try again later.'
};

export const FINANCIAL_VALUES = {
  averagePricePerVolume: "Gross Revenue",
  netRevenue: "Net Revenue",
  grossProfitMargin: "Gross Profit",
};
export const ACCESS_LEVEL = {
  MARKET: "markets",
  PLAN: "plans",
  PORTFOLIO: "portfolios",
};
export const USER_ACCESS_LEVEL = {
  PORTFOLIO: "portfolioLevelAccess",
  BRAND: "brandLevelAccess",
};
export const ROLES = {
  SUPER_USER: 1,
  ADMIN: 2,
  APPROVER: 3,
  EDITOR: 4,
  PARTNER: 5,
    VIEWER: 6,
};
export const AIRTHMETIC_OPERARTORS = {
  "<": function (userRole, roleCondition) {
    return userRole < roleCondition;
  },
  "<=": function (userRole, roleCondition) {
    return userRole <= roleCondition;
  },
  ">": function (userRole, roleCondition) {
    return userRole > roleCondition;
  },
  ">=": function (userRole, roleCondition) {
    return userRole >= roleCondition;
  },
  "==": function (userRole, roleCondition) {
    return userRole == roleCondition;
  },
  "!=": function (userRole, roleCondition) {
    return userRole != roleCondition;
  },
  "===": function (userRole, roleCondition) {
    return userRole === roleCondition;
  },
  "!==": function (userRole, roleCondition) {
    return userRole !== roleCondition;
  },
  // ...
};

export const fileMimeTypes = {
    pdf: 'application/pdf',
    txt: 'text/plain',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    zip: 'application/zip',
    xls: 'application/vnd.ms-excel',
    pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    ppt: 'application/vnd.ms-powerpoint',
    png: 'image/png',
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    js: 'text/javascript',
    json: 'application/json',
    gif: 'image/gif',
    xml: 'application/xml'
  };
  
 
