import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "environments/environment";
import { HttpApiService } from "app/core/http-core/api/http-api.service";
import { IHttpApiRequest } from "app/core/http-core/api/interface/ihttp-api-request";
import { Observable } from "rxjs";

const BASE_API_URL = `${environment.api}/v3`;
const UPFRONT_API = `${environment.api}/v3/upFront`;
const MJTBD_API = `${environment.api}/v3/mjtbd`;
const CAMPAIGN_API_URL = `${BASE_API_URL}/campaign`;
@Injectable({
  providedIn: "root",
})
export class PlanService {
  constructor(
    private http: HttpClient,
    private httpApiService: HttpApiService
  ) {}
  public getPlanData(obj: any) {
    return this.http.post(`${BASE_API_URL}/plan`, obj);
  }

  public getOverviewData(obj: any) {
    return this.http.post(`${BASE_API_URL}/plan/getOverviewData`, obj);
  }

  public getPortfolioInfo(postObj) {
    return this.http.post(`${UPFRONT_API}/getUpfrontPerYear`, postObj);
  }

  public getFinancialValuePortfolio(postObj) {
    return this.http.post(
      `${UPFRONT_API}/getFinancialValueForPortfolio`,
      postObj
    );
  }
  public getFinancialValueFromPortfolioForSubBrand(postObj) {
    return this.http.post(
      `${UPFRONT_API}/getFinancialValueForPortfolioForSubbrand`,
      postObj
    );
  }

  getSubBrandsScenarioData(postObj) {
    return this.http.post(`${UPFRONT_API}/getPlanEntityData`, postObj);
  }

  getScenarioDetailsBySubBrand(postObj) {
    return this.http.post(`${UPFRONT_API}/getScenarioForPlanEntity`, postObj);
  }

  createBaseLineScenario(postObj) {
    return this.http.post(`${UPFRONT_API}/createBaseLineScenario`, postObj);
  }

  getLinearFactorsBySubBrand(postObj) {
    return this.http.post(`${UPFRONT_API}/getLinearFactors`, postObj);
  }
  getNetworkFactorSettings(postObj) {
    return this.http.post(`${UPFRONT_API}/getNetworkFactorsSettings`, postObj);
  }

  updateScenarioName(postObj) {
    return this.http.post(`${UPFRONT_API}/updateScenarioName`, postObj);
  }

  lockChannel(postObj) {
    return this.http.post(`${BASE_API_URL}/optimizer/lockChannel`, postObj);
  }

  optimizeScenario(postObj) {
    return this.http.post(
      `${BASE_API_URL}/optimizer/optimizeScenario`,
      postObj
    );
  }

  saveChannel(channelDetails) {
    return this.http.post(`${UPFRONT_API}/saveChannel`, channelDetails);
  }
  saveChannelList(channelDetailList) {
    return this.http.post(`${UPFRONT_API}/saveChannelList`, channelDetailList);
  }
  getChannelBudgetLimit(channelDetails) {
    return this.http.post(
      `${UPFRONT_API}/getChannelBudgetLimit`,
      channelDetails
    );
  }

  getScenarioAnalytics(scenarioChnlDetails) {
    return this.http.post(
      `${environment.api}/v3/analytics/getScenarioAnalytics`,
      scenarioChnlDetails
    );
  }
  getScenarioPlot(scenarioPlotObj) {
    return this.http.post(
      `${environment.api}/v3/analytics/getScenarioPlot`,
      scenarioPlotObj
    );
  }

  getMediaChannels(referenceObj) {
    return this.http.post(`${UPFRONT_API}/getChannels`, referenceObj);
  }

  getCurvePerChannel(channelObj) {
    return this.http.post(`${UPFRONT_API}/getCurvePerChannel`, channelObj);
  }

  getScenariosForPlan(PlanObj) {
    return this.http.post(`${UPFRONT_API}/getAllScenariosForPlan`, PlanObj);
  }

  deleteScenario(scenarioId) {
    return this.http.post(`${UPFRONT_API}/deleteScenario`, { scenarioId });
  }

  deleteScenarioChannel(
    cWMBudget,
    channelId,
    year?,
    scenarioId?,
    commsTaskId?
  ) {
    if (year && scenarioId && commsTaskId) {
      return this.http.post(`${UPFRONT_API}/deleteScenarioChannel`, {
        cWMBudget,
        channelId,
        year,
        scenarioId,
        commsTaskId,
      });
    } else {
      return this.http.post(`${UPFRONT_API}/deleteScenarioChannel`, {
        channelId,
      });
    }
  }

  getChartData(chartObj: any) {
    return this.http.post(`${BASE_API_URL}/plan/getChartData`, chartObj);
  }
  getWidgetChartData(chartObj: any) {
    return this.http.post(`${BASE_API_URL}/plan/getWidgetChartData`, chartObj);
  }

  getFlowPackView(chartObj: any) {
    return this.http.post(`${BASE_API_URL}/plan/getFlowPackView`, chartObj);
  }

  getMediaMix(chartObj: any) {
    return this.http.post(`${BASE_API_URL}/plan/getMediaMix`, chartObj);
  }

  duplicateScenario(scenarioDetails) {
    return this.http.post(`${UPFRONT_API}/duplicateScenario`, scenarioDetails);
  }

  updateScenario(scenarioDetails) {
    return this.http.post(`${UPFRONT_API}/editScenario`, scenarioDetails);
  }

  updateUpfrontPlan(upfrontData) {
    return this.http.post(`${UPFRONT_API}/updateUpfrontPlan`, upfrontData, {
      responseType: "text",
    });
  }
  getHierarchy(obj) {
    return this.http.post(`${BASE_API_URL}/plan/getHierarchy`, obj);
  }

  updateUpfrontOverview(upfrontData) {
    return this.http.post(`${UPFRONT_API}/updateUpfrontOverview`, upfrontData, {
      responseType: "text",
    });
  }

  updateBrandTarget(targetObj) {
    return this.http.post(`${BASE_API_URL}/plan/updateBrandTarget`, targetObj);
  }

  updateTCParchitecture(tcpObj) {
    return this.http.post(`${BASE_API_URL}/plan/updateTCParchitecture`, tcpObj);
  }

  getTcpProgressChartData(mjtbdObj) {
    return this.http.post(`${BASE_API_URL}/tcp/getConsolidatedView`, mjtbdObj);
  }

  saveMJTBD(mjtbdObj) {
    return this.http.post(`${MJTBD_API}/saveMJTBD`, mjtbdObj);
  }

  updateMJTBDForm(mjtbdObj) {
    return this.http.post(`${MJTBD_API}/updateMJTBDForm`, mjtbdObj);
  }

  deleteMJTBD(mjtbdObj) {
    return this.http.post(`${MJTBD_API}/deleteMJTBD`, mjtbdObj);
  }

  getBrandObjectives() {
    return this.http.post(`${MJTBD_API}/getBrandObjectives`, {});
  }

  getMJTBDFormDropDowns(brandObj) {
    return this.http.post(`${MJTBD_API}/getMJTBDFormDropDowns`, brandObj);
  }

  getMJTBDAudience(brandObj) {
    return this.http.post(`${MJTBD_API}/getMJTBDAudience`, brandObj);
  }

  getMJTBDcommsObj(brandObj) {
    return this.http.post(`${MJTBD_API}/getMJTBDcommsObj`, brandObj);
  }

  getMJTBDForm(formObj) {
    return this.http.post(`${MJTBD_API}/getMJTBDForm`, formObj);
  }
  genrateTCPBriefTemplate(formObj) {
    return this.http.post(
      `${BASE_API_URL}/tcp/generateOptBriefPTTemplate`,
      formObj
    );
  }
  genrateTCPArchitectureTemplate(formObj) {
    return this.http.post(
      `${BASE_API_URL}/tcp/generateArchitecturePTTemplate`,
      formObj
    );
  }
  genrateBRAND_GROWTHTemplate(formObj) {
    return this.http.post(
      `${BASE_API_URL}/tcp/generateBrandGrowthPTTemplate`,
      formObj
    );
  }
  genrateKPI_FRAMEWORK_Template(formObj) {
    return this.http.post(
      `${BASE_API_URL}/tcp/genrateKPIFrameworkTemplate`,
      formObj
    );
  }
  genrateTCPCAMPAIGN_IDEATemplate(formObj) {
    return this.http.post(
      `${BASE_API_URL}/tcp/generateCAMPAIGN_IDEAPTTemplate`,
      formObj
    );
  }
  genrateTCPAudienceConsidrationfileDownload(formObj) {
    return this.http.post(
      `${BASE_API_URL}/tcp/audienceConsidrationDownload`,
      formObj
    );
  }
  genrateTCPContentDistributionStrategyfileDownload(formObj) {
    return this.http.post(
      `${BASE_API_URL}/tcp/contentDistributionStrategy`,
      formObj
    );
  }
  uploadtcpdocs(formObj) {
    return this.http.post(`${BASE_API_URL}/tcp/tcpdocsupload`, formObj);
  }
  getTCPDocData(formObj) {
    return this.http.post(`${BASE_API_URL}/tcp/gettcpdocsdata`, formObj);
  }
  deleteTCPDocData(formObj) {
    return this.http.post(`${BASE_API_URL}/tcp/deleteTCPDoc`, formObj);
  }
  getKpiFramworkTemplateData(formObj) {
    return this.http.post(
      `${BASE_API_URL}/tcp/getKpiFramworkTemplateData`,
      formObj
    );
  }
  validateMJTBDDelete(mjtbdObj) {
    return this.http.post(`${MJTBD_API}/validateMJTBDDelete`, mjtbdObj);
  }
  getCampaignsFromTM(obj: any) {
    return this.http.post(`${CAMPAIGN_API_URL}/getCampaignsFromTM`, obj);
  }
  getCampaignsByMjtbd(obj: any) {
    return this.http.post(`${CAMPAIGN_API_URL}/getCampaignsByMjtbd`, obj);
  }
  downloadTCPDoc(mjtbdObj) {
    // window.open(`${BASE_API_URL}/tcp/downloadTCPDoc?filePath=${mjtbdObj.filePath}&fileName=${mjtbdObj.fileName}`);
    return this.http.post(
      `${BASE_API_URL}/tcp/downloadTCPDoc?filePath=${mjtbdObj.filePath}&fileName=${mjtbdObj.fileName}`,
      {}
    );
  }
  getAllYears() {
    return this.http.post(`${BASE_API_URL}/plan/getAllYears`, {});
  }
  getDefaultMarketName(obj: any) {
    return this.http.post(`${BASE_API_URL}/plan/getDefaultMarketName`, obj);
  }
  getPlanAndPortfolio(obj: any) {
    return this.http.post(`${BASE_API_URL}/plan/getPlanAndPortfolio`, obj);
  }

  public getPlanImportData(obj: any) {
    return this.http.post(`${BASE_API_URL}/plan/getMJTBDFormImport`, obj);
  }

  getAndSaveImportMJTBD(formObj) {
    return this.http.post(`${MJTBD_API}/getMJTBDFormDataToImport`, formObj);
  }
  sendNotifications(obj) {
    return this.http.post(`${MJTBD_API}/sendNotifications`, obj);
  }
  public updateTargetROI(obj: any) {
    return this.http.post(`${BASE_API_URL}/plan/updateTargetROI`, obj);
  }
  public resetTargetROI(obj: any) {
    return this.http.post(`${BASE_API_URL}/plan/resetTargetROI`, obj);
  }
  public getTargetROI(obj: any) {
    return this.http.post(`${BASE_API_URL}/plan/getTargetROI`, obj);
  }
  public getROIeVersions() {
    return this.http.get(`${BASE_API_URL}/roieapi/getROIeVersions`);
  }
  public getCurveCompilationfromROIe(obj: any) {
    return this.http.get(
      `${BASE_API_URL}/roieapi/getCurveCompilationfromROIe`,
      obj
    );
  }

  // /**
  //  * @name getVersionsForROIAPI
  //  * @description getVersionsForROIAPI
  //  * @param {obj}
  //  * @returns {Observable<Response>}
  //  */
  // public getVersionsForROIAPI(postObj: any): Observable<any> {
  //   const request: IHttpApiRequest = {
  //     config: {
  //       resource: `v1_5/analytic/versions`,
  //       apiUrl: "https://roie-ext-uat.cdp.pepstaging.com/",
  //       useMockData: false,
  //       mockDataPath: "",
  //       group: "commsTask",
  //       noCache: true,
  //       showApiLoader: false,
  //       showApiErrorDialog: true,
  //       showApiTimeLog: true,
  //       headers: { authorization: "Bearer 229d729771cc484e8436f8dabbc20160" },
  //       latency: undefined,
  //     },
  //     args: {},
  //     urlTemplate: "",
  //   };
  //   return this.httpApiService.get(request);
  // }
  // /**
  //  * @name getCurveCompilationfromROIe
  //  * @description getCurveCompilationfromROIe
  //  * @param {obj}
  //  * @returns {Observable<Response>}
  //  */
  // public getCurveCompilationfromROIe(postObj: any): Observable<any> {
  //   const request: IHttpApiRequest = {
  //     config: {
  //       resource: `v1_5/reports/canvas_compilation?run_name=a8698278ec5a65f67598f6fe925b54aad99d8351&market=NA&data_load_versions=base_5613,competitors_5647,margins_5721,media_5729,mediacppinit_5608,sales_5721,trade_5716&FY 23 Closed=FY 23 Closed`,
  //       apiUrl: "https://roie-ext-uat.cdp.pepstaging.com/",
  //       useMockData: false,
  //       mockDataPath: "",
  //       group: "commsTask",
  //       noCache: true,
  //       showApiLoader: false,
  //       showApiErrorDialog: true,
  //       showApiTimeLog: true,
  //       headers: { authorization: "Bearer 229d729771cc484e8436f8dabbc20160" },
  //       latency: undefined,
  //     },
  //     args: {},
  //     urlTemplate: "",
  //   };
  //   return this.httpApiService.get(request);
  // }
  // // /**
  // //  * @name getCurveCompilationfromROIe
  // //  * @description getCurveCompilationfromROIe
  // //  * @param {obj}
  // //  * @returns {Observable<response>}
  // //  */
  // // public getCurveCompilationfromROIe(postObj: any): Observable<any> {
  // //   const request: IHttpApiRequest = {
  // //     config: {
  // //       resource: ``,
  // //       apiUrl:
  // //         "https://roie-ext-uat.cdp.pepstaging.com/v1_5/reports/canvas_compilation",
  // //       useMockData: false,
  // //       mockDataPath: "",
  // //       group: "commsTask",
  // //       noCache: true,
  // //       showApiLoader: false,
  // //       showApiErrorDialog: true,
  // //       showApiTimeLog: true,
  // //       headers: { authorization: "Bearer 229d729771cc484e8436f8dabbc20160" },
  // //       latency: undefined,
  // //     },
  // //     data: postObj,
  // //     args: {},
  // //     urlTemplate: "",
  // //   };
  // //   console.log("request", request);
  // //   return this.httpApiService.post(request);
  // // }

  // public getVersions() {
  //   const apiUrl =
  //     "https://roie-ext-uat.cdp.pepstaging.com/v1_5/analytic/versions";
  //   const obj = {
  //     headers: { authorization: "Bearer 229d729771cc484e8436f8dabbc20160" },
  //   };
  //   return this.http.get(apiUrl, obj);
  // }

  // public getROIeData(obj: any) {
  //   const headersconfig = {
  //     headers: { authorization: "Bearer 229d729771cc484e8436f8dabbc20160" },
  //   };
  //   return this.http.get(
  //     "https://roie-ext-uat.cdp.pepstaging.com/v1_5/reports/canvas_compilation?run_name=a8698278ec5a65f67598f6fe925b54aad99d8351&market=NA&data_load_versions=base_5613,competitors_5647,margins_5721,media_5729,mediacppinit_5608,sales_5721,trade_5716&FY 23 Closed=FY 23 Closed",
  //     headersconfig
  //   );
  // }

  public getDatoramaData(){
    return this.http.get(`${BASE_API_URL}/plan/getDatoramaData`);
  }
}
