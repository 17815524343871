import { Observable, throwError, BehaviorSubject, Subject } from "rxjs";
import { environment } from "environments/environment";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import ab2b64 from "ab2b64";
import { IMarketCurrency } from "../../common/interfaces/market";
import { ITeamMembers, ITeamMembersReq } from "../../common/interfaces/common";

const BASE_API_URL = `${environment.api}/v3`;
const ALERT_API_URL = `${environment.api}/v3/alerts`;
const MJTBD_API = `${environment.api}/v3/mjtbd`;

@Injectable({
  providedIn: "root",
})
export class GlobalElementsService {
  constructor(private httpClient: HttpClient) {}

  private saveEventSource = new Subject<any>();
  private cancelEventSource = new Subject<any>();
  private disableSaveSource = new Subject<any>();
  private showSaveBtnSource = new Subject<any>();

  public getSidePanelSaveEvent = this.saveEventSource.asObservable();
  public getSidePanelCancelEvent = this.cancelEventSource.asObservable();
  public getSidePanelSaveEnableState = this.disableSaveSource.asObservable();
  public getShowSaveBtn = this.showSaveBtnSource.asObservable();

  setShowSaveBtn(showSave: boolean) {
    this.showSaveBtnSource.next(showSave);
  }

  setSidePanelSaveEvent() {
    this.saveEventSource.next();
  }

  setSidePanelCancelEvent() {
    this.cancelEventSource.next();
  }

  setSaveEnableState(shouldDisable: boolean) {
    this.disableSaveSource.next(shouldDisable);
  }

  // .............. Backend API calls .......................
  public getAllUsers(gpid: string) {
    return this.httpClient.post(`${BASE_API_URL}/userMgmt/getAllUsers`, {
      gpid,
    });
  }

  public getUserData(gpid: string) {
    return this.httpClient.post(`${BASE_API_URL}/userMgmt/getUserData`, {
      gpid,
    });
  }
  public bulkUserUpload(_obj: any) {
    return this.httpClient.post(
      `${BASE_API_URL}/userMgmt/bulkUserUpload`,
      _obj
    );
  }

  public getAllMarket(gpid: string) {
    return this.httpClient.post(`${BASE_API_URL}/userMgmt/getAllMarkets`, {
      gpid,
    });
  }
  public getAllMarketsAndPortfolios() {
    return this.httpClient.post(
      `${BASE_API_URL}/userMgmt/getAllMarketsAndPortfolios`,
      {}
    );
  }

  public getAllPortfolioRTMData(marketId: string) {
    return this.httpClient.post(
      `${BASE_API_URL}/portfolioRTMDash/getPortfolioRTMDashData`,
      { marketId }
    );
  }
  public savePortfolioRTMData(portfolioId: string, RTOLink: string) {
    return this.httpClient.post(
      `${BASE_API_URL}/portfolioRTMDash/savePortfolioRTMDash`,
      { portfolioId, RTOLink }
    );
  }

  public deleteUsers(obj: any) {
    return this.httpClient.post(`${BASE_API_URL}/userMgmt/deleteUser`, obj);
  }

  public createUser(userObj: any) {
    return this.httpClient.post(`${BASE_API_URL}/userMgmt/createUser`, userObj);
  }

  public editUser(userObj: any) {
    return this.httpClient.post(`${BASE_API_URL}/userMgmt/editUser`, userObj);
  }

  public getCanvasYears() {
    return this.httpClient.post(`${BASE_API_URL}/userMgmt/getYears`, {});
  }

  public addNextWorkingYear() {
    return this.httpClient.post(`${BASE_API_URL}/userMgmt/addYear`, {});
  }
  public getPlansForMarket(obj: any) {
    return this.httpClient.post(
      `${BASE_API_URL}/userMgmt/getPlansforMarket`,
      obj
    );
  }

  //fetch market data
  public getAllSelecetdRoleByPF(obj: any) {
    return this.httpClient.post(
      `${BASE_API_URL}/userMgmt/getAllSelecetdRoleByPF`,
      obj
    );
  }

  //fetch market data with plan, pf and roles
  public getAllPortfoliosPlansAndRolesbyPF(obj: any) {
    return this.httpClient.post(
      `${BASE_API_URL}/userMgmt/getAllPortfoliosPlansAndRolesbyPF`,
      obj
    );
  }

  getAllMarketsData(): Observable<any> {
    return this.httpClient
      .post<any>(`${BASE_API_URL}/brand/getMarkets`, {})
      .pipe(catchError(this.handleError));
  }

  getAllPortfoliosData(obj: any): Observable<any> {
    return this.httpClient
      .post<any>(`${BASE_API_URL}/userMgmt/getAllPortfolios`, obj)
      .pipe(catchError(this.handleError));
  }
  getAllPersonasData(): Observable<any> {
    return this.httpClient
      .post<any>(`${BASE_API_URL}/userMgmt/getAllPersonas`, {})
      .pipe(catchError(this.handleError));
  }

  getAllRolesData(): Observable<any> {
    return this.httpClient
      .post<any>(`${BASE_API_URL}/userMgmt/getAllRoles`, {})
      .pipe(catchError(this.handleError));
  }

  //fetch market data
  getMarketData(): Observable<any> {
    return this.httpClient
      .post<any>(`${BASE_API_URL}/userProfile/getMarkets`, {})
      .pipe(catchError(this.handleError));
  }
  //fetch portfolio data
  getDefaultPageDropDownData(obj: any): Observable<any> {
    return this.httpClient
      .post<any>(`${BASE_API_URL}/userProfile/getDefaultPageDropDowns`, obj)
      .pipe(catchError(this.handleError));
  }

  //save user profile
  saveUserProfile(obj: any): Observable<any> {
    return this.httpClient
      .post<any>(`${BASE_API_URL}/userProfile/saveUser`, obj)
      .pipe(catchError(this.handleError));
  }
  //avatar upload file
  uploadAvatar(avatarObj: any) {
    return this.httpClient
      .post<any>(`${BASE_API_URL}/userProfile/uploadAvatar`, avatarObj)
      .pipe(catchError(this.handleError));
  }

  getUserAvatar(imgName: string) {
    return this.httpClient.post(
      `${BASE_API_URL}/userProfile/getUserAvatar`,
      { imgName },
      { responseType: "blob" }
    );
  }
  getpsdMetrics() {
    return this.httpClient.get(`${BASE_API_URL}/psdMetrics/getpsdMetrics`);
  }
  getDocuments(path: string) {
    return this.httpClient.get(
      `${BASE_API_URL}/sharepoint/searchAll?path=/${path}/`
    );
  }
  getDocumentsWithFilesAPI(spFolderpath: string) {
    return this.httpClient.get(
      `${BASE_API_URL}/sharepoint/getFilesFromSPFolder?spFolderpath=/${spFolderpath}`
    );
  }
  getDocumentFromSubFolderAPI(spParentFolderpath: string) {
    return this.httpClient.get(
      `${BASE_API_URL}/sharepoint/getSPFilesFromSubFolder?spParentFolderpath=/${spParentFolderpath}`
    );
  }

  getCurrencyExchange(marketId: number) {
    return this.httpClient.get(
      `${BASE_API_URL}/market/getMarketExchangeRate?mktId=${marketId}`
    );
  }

  setCurrencyExchangeRate(marketCurrenxy: IMarketCurrency) {
    return this.httpClient.post(
      `${BASE_API_URL}/market/updateMarketExchangeRate`,
      marketCurrenxy
    );
  }

  uploadDocuments(postObj) {
    // let Base64String = ab2b64.ab2b64(postObj.body.data);
    // var Base64String = btoa(String.fromCharCode.apply(null, new Uint8Array(postObj.body.data)));
    // const buffer = Buffer.from(postObj.body.data);

    // const base64String = buffer.toString('base64');
    postObj.fileDataBase64 = postObj.body.data;
    // this.httpClient.MaxResponseContentBufferSize = 9999999;
    // this.httpClient.
    return this.httpClient.post(
      `${BASE_API_URL}/sharepoint/uploadFile`,
      postObj
    );
  }

  deleteSharePointFile(postObj) {
    return this.httpClient.post(
      `${BASE_API_URL}/sharepoint/deleteFile`,
      postObj
    );
  }
  
  //Edit campaign studies file name
  editFileName(postObj) {
    return this.httpClient.post(
      `${BASE_API_URL}/sharepoint/editFileName`,
      postObj
    );
  }

  downloadSharepointFile(postObj) {
    return this.httpClient.post(
      `${BASE_API_URL}/sharepoint/downloadFile`,
      postObj
    );
  }

  /* ***************** Brand management start ************* */
  //get all brands
  getAllBrands(brandsObj: any) {
    return this.httpClient
      .post<any>(`${BASE_API_URL}/brand/getBrands`, brandsObj)
      .pipe(catchError(this.handleError));
  }
  //delete brands
  deleteBrands(brandsObj: any) {
    return this.httpClient
      .post<any>(`${BASE_API_URL}/brand/deleteBrand`, brandsObj)
      .pipe(catchError(this.handleError));
  }

  //get plans based on portfolio
  getPlans(planObj: any) {
    return this.httpClient
      .post<any>(`${BASE_API_URL}/portfolio/`, planObj)
      .pipe(catchError(this.handleError));
  }
  //get all assets
  getAllAssets(brandName) {
    return this.httpClient
      .post<any>(`${BASE_API_URL}/brand/getAssets`, { brandName })
      .pipe(catchError(this.handleError));
  }
  getAllAssetsProgramPage() {
    return this.httpClient
      .post<any>(`${BASE_API_URL}/brand/getAssetsProgramPage`, {})
      .pipe(catchError(this.handleError));
  }

  //save brand
  saveBrand(brandObj: any) {
    return this.httpClient
      .post<any>(`${BASE_API_URL}/brand/saveBrand`, brandObj)
      .pipe(catchError(this.handleError));
  }
  //edit brand
  editBrand(brandObj: any) {
    return this.httpClient
      .post<any>(`${BASE_API_URL}/brand/editBrand`, brandObj)
      .pipe(catchError(this.handleError));
  }
  //edit brand
  uploadSEtemplate(uploadObj: any) {
    return this.httpClient
      .post<any>(`${BASE_API_URL}/socailEngagement/uploadTemplate`, uploadObj)
      .pipe(catchError(this.handleError));
  }
  uploadCTtemplate(uploadObj: any) {
    return this.httpClient
      .post<any>(`${BASE_API_URL}/commsTask/uploadTemplate`, uploadObj)
      .pipe(catchError(this.handleError));
  }

  downloadSEtemplate(uploadObj: any) {
    // window.open(`${BASE_API_URL}/socailEngagement/downloadTemplate?marketId=${uploadObj.marketId}&year=${uploadObj.year}&portfolioId=${uploadObj.portfolioId?uploadObj.portfolioId:''}&planId=${uploadObj.planId?uploadObj.planId:''}`);
    return this.httpClient.post<any>(
      `${BASE_API_URL}/socailEngagement/downloadTemplate?marketId=${
        uploadObj.marketId
      }&year=${uploadObj.year}&portfolioId=${
        uploadObj.portfolioId ? uploadObj.portfolioId : ""
      }&planId=${uploadObj.planId ? uploadObj.planId : ""}`,
      {}
    );
  }

  getSEuploadData() {
    return this.httpClient
      .post<any>(`${BASE_API_URL}/socailEngagement/getUploadData`, {})
      .pipe(catchError(this.handleError));
  }
  getMasterWidgetList() {
    return this.httpClient
      .post<any>(`${BASE_API_URL}/socailEngagement/getMasterWidgetList`, {})
      .pipe(catchError(this.handleError));
  }
  updateWidgetConfiguration(data: any) {
    return this.httpClient.post<any>(
      `${BASE_API_URL}/socailEngagement/updateWidgetValueType`,
      data
    );
  }
  /* ***************** Brand management end ************* */
  /* ***************** Notifications start ************* */

  getApproversList(approverObj) {
    return this.httpClient.post(
      `${ALERT_API_URL}/getApproversList`,
      approverObj
    );
  }

  getTeamMembersList(teamMemberReq: ITeamMembersReq) {
    return this.httpClient.post(
      `${ALERT_API_URL}/getTeamMembersList`,
      teamMemberReq
    );
  }

  getNotifications() {
    return this.httpClient.post(`${ALERT_API_URL}/getNotifications`, {});
  }

  readNotifications(notificatioObj) {
    return this.httpClient.post(
      `${ALERT_API_URL}/readNotifications`,
      notificatioObj
    );
  }

  /* ***************** Notifications end ************* */

  getProgramsFromMDM(postObj) {
    return this.httpClient.post(`${BASE_API_URL}/brand/getProgramsFromMDM`, {});
  }

  getPlansFromMDM() {
    return this.httpClient.post(`${BASE_API_URL}/brand/getPlansFromMDM`, {});
  }

  getMarketsFromMDM() {
    return this.httpClient.post(`${BASE_API_URL}/brand/getMarketsfromMDM`, {});
  }

  handleError(error: any) {
    return throwError(error.statusText || "Server Error");
  }

  getPortfoliosByMkt(marketId) {
    return this.httpClient.post(`${BASE_API_URL}/userProfile/getPortfolios`, {
      marketId,
    });
  }

  getOptimerQueue() {
    return this.httpClient.post(`${BASE_API_URL}/optimizer/getOptimizer`, {});
  }

  getThumbnailBase64DataSharePoint(url: string) {
    return this.httpClient.post(
      `${BASE_API_URL}/sharepoint/thumbnailbase64data/`,
      { thumbnailurl: url }
    );
  }
  getThumbnailBase64DataSharePoint_new(postObj: Object) {
    return this.httpClient.post(
      `${BASE_API_URL}/sharepoint/thumbnailbase64dataNew/`,
      postObj
    );
  }
  downloadFile(postObj) {
    return this.httpClient.post(
      `${BASE_API_URL}/sharepoint/downloadfile/`,
      postObj
    );
  }

  uploadCampaignIdeaImage(data: any) {
    return this.httpClient.post<any>(
      `${BASE_API_URL}/userProfile/CampaignIdeaImage`,
      { data }
    );
  }

  getCampaignImage(data: any) {
    return this.httpClient.post<any>(
      `${BASE_API_URL}/campaign/getCampaignImage`,
      data
    );
  }
  importDocuments(postObj) {
    return this.httpClient.post(
      `${BASE_API_URL}/sharepoint/importDocuments/`,
      postObj
    );
  }
  getWidgetPlanedData(data: any) {
    return this.httpClient.post<any>(
      `${BASE_API_URL}/plan/getPlanWidgetTarget`,
      data
    );
  }
  updateWidgetPlanedData(data: any) {
    return this.httpClient.post<any>(
      `${BASE_API_URL}/plan/updateWidgetData`,
      data
    );
  }
  customAudienceSave(data: any) {
    return this.httpClient.post<any>(
      `${BASE_API_URL}/customAudience/save`,
      data
    );
  }
  customAudienceDelete(data: any) {
    return this.httpClient.post<any>(
      `${BASE_API_URL}/customAudience/delete`,
      data
    );
  }
  customAudienceGetAll(data: any) {
    return this.httpClient.post<any>(
      `${BASE_API_URL}/customAudience/getAll`,
      data
    );
  }
  getChannelsListbyMarket(marketId: any) {
    return this.httpClient.post(
      `${BASE_API_URL}/commsTask/getChannelsListbyMarket`,
      { marketId }
    );
  }
  deleteChannel(channelId) {
    return this.httpClient.post(`${BASE_API_URL}/commsTask/deleteChannel`, {
      channelId,
    });
  }
  updateChannelInfo(obj: any) {
    return this.httpClient.post(`${BASE_API_URL}/commsTask/updateChannel`, obj);
  }
  downloadChannelTemplate(marketId: any) {
    window.open(
      `${BASE_API_URL}/commsTask/downloadChannelTemplate?marketId=${marketId}`
    );
  }
  downloadBulkUploadUserTemplate() {
    window.open(`${BASE_API_URL}/userMgmt/downloadBulkUserUploadTemplate`);
  }

  /* ***************** Tooltip Management Start ************* */
  addUpdateTooltip(obj) {
    return this.httpClient.post(`${BASE_API_URL}/tooltipmanagement`, obj);
  }
  getTooltipData(tooltipCatCode = "", marketId = "") {
    return this.httpClient.get(
      `${BASE_API_URL}/tooltipmanagement/?tooltipCatCode=${tooltipCatCode}&marketId=${marketId}`
    );
  }
  addUpdateTooltipCat(obj) {
    return this.httpClient.post(
      `${BASE_API_URL}/tooltipmanagement/addUpdateTooltipCat`,
      obj
    );
  }
  getTooltipCatData(tooltipCatCode = "") {
    return this.httpClient.get(
      `${BASE_API_URL}/tooltipmanagement/getTooltipCat?tooltipCatCode=${tooltipCatCode}`
    );
  }
  deleteTooltipCat(obj) {
    return this.httpClient.post(
      `${BASE_API_URL}/tooltipmanagement/deleteTooltipCat`,
      obj
    );
  }
  deleteTooltip(obj) {
    return this.httpClient.post(
      `${BASE_API_URL}/tooltipmanagement/deleteTooltip`,
      obj
    );
  }
  getbrandMappingData(){
    return this.httpClient.get(`${BASE_API_URL}/brandmapping/getBrandMappingData`)
  }
  getROIBrandList(){
    return this.httpClient.get(`${BASE_API_URL}/brandmapping/getROIBrandList`)
  }
  

  saveBrandMappingRows(obj){
    return this.httpClient.post(`${BASE_API_URL}/brandmapping/saveBrandMappingRows`, obj);
  }

  saveAuditHistory(obj){
    return this.httpClient.post(`${BASE_API_URL}/brandmapping/saveAuditHistory`, obj);
  }
  getAuditHistoryData(elementId){
    return this.httpClient.post(`${BASE_API_URL}/brandmapping/getAuditHistory`,{elementId});
  }
  deactivateBrandMapping(obj){
    return this.httpClient.post(`${BASE_API_URL}/brandmapping/changeStatusBrandMapping`,obj);
 }
  /* ***************** Tooltip Management End ************* */
  /* Campaign Studies */
  public getBrandsList() {
    return this.httpClient.post(`${BASE_API_URL}/campaign/getBrandsList`, {});
  }
  public getBrandsListByMarket(marketObj) {
    return this.httpClient.post(
      `${BASE_API_URL}/campaign/getBrandsListByMarket`,
      marketObj
    );
  }
  getBrandObjectives() {
    return this.httpClient.post(`${MJTBD_API}/getBrandObjectives`, {});
  }
  getCommsObjective(brandObj) {
    return this.httpClient.post(`${MJTBD_API}/getCommsObjective`, brandObj);
  }
  getCampaignStudiesData() {
    return this.httpClient.get(
      `${BASE_API_URL}/campaign/getCampaignStudiesData`
    );
  }
  checkFileExists(fileName) {
    return this.httpClient.post(`${BASE_API_URL}/campaign/checkFileExists`, {
      fileName,
    });
  }
  checkFileCounts(campaignstudiesId) {
    return this.httpClient.post(`${BASE_API_URL}/campaign/checkFileCounts`, {
      campaignstudiesId,
    });
  }
  deleteCampaignStudiesData(campaignId) {
    return this.httpClient.post(`${BASE_API_URL}/campaign/deleteCampaignStudiesData`, {
      campaignId,
    });
  }
  saveCampaignStudiesInfo(campaignStudiesObj) {
    return this.httpClient.post(
      `${BASE_API_URL}/campaign/saveCampaignStudiesInfo`,
      campaignStudiesObj
    );
  }
  updateCampaignStudiesInfo(campaignStudiesObj) {
    return this.httpClient.post(
      `${BASE_API_URL}/campaign/updateCampaignStudiesInfo`,
      campaignStudiesObj
    );
  }
  updateCount(campaignStudiesObj) {
    return this.httpClient.post(
      `${BASE_API_URL}/campaign/updateCount`,
      campaignStudiesObj
    );
  }
  handleMarketState(marketId){
    return this.httpClient.post(`${BASE_API_URL}/plan/getMarketCount`, {marketId} );
  }
  getCurveCompilationData(paramsId,marketId){
    return this.httpClient.get(`${BASE_API_URL}/roieapi/getCurveCompilationData?subBrandId=${paramsId}&marketId=${marketId}`);
  }
}
